// Dependencies
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import 'twin.macro';
import { Progress, Result } from 'antd';

// Env
import env from '@env';

// Firestore DB
import db from '@db';

// Provider containers
import UserData from '@providers/UserData';
import ProcessableProductsObject from '@providers/ProcessableProductsObject';

// CSS Overrides
import resultOverrideStyles from './resultOverrideStyles.css';

/**
 * @component CurrentStatus
 * Displays the current status of the inventory upload.
 * Shows the number of products succesfully uploaded and errors.
 *
 * @state {number} numItems - the total number of items user inventory contains
 * @state {number} numDoneItems - the total numbers of items succesfully processed by Square
 * @state {array.<Error>} errors - array containing all errors reported by cloud functions during upload
 */
export default function CurrentStatus() {
  const userData = UserData.useContainer();
  const processableProductsObject = ProcessableProductsObject.useContainer();
  const [numItems, setNumItems] = useState(0);
  const [numDoneItems, setNumDoneItems] = useState(0);
  const [errors, setErrors] = useState([]);

  /**
   * On component mount useEffect:
   * Creates 2 listeners to user's Firestore inventory.
   * One get all items with a status of 'DONE', which means they have been succesfully processed.
   * The other gets all items with a status of 'ERROR.
   *
   * When the component unmounts the listeneres are unsubscribed
   */
  useEffect(() => {
    setNumItems(processableProductsObject[0].items.length);
    const unsubscribe1 = db
      .collection(`${env().ENVIRONMENT}-inventories`)
      .doc(userData.merchantId)
      .collection('items')
      .where('status', '==', 'DONE')
      .onSnapshot(querySnapshot => {
        setNumDoneItems(querySnapshot.size);
      });

    const unsubscribe2 = db
      .collection(`${env().ENVIRONMENT}-inventories`)
      .doc(userData.merchantId)
      .collection('items')
      .where('status', '==', 'ERROR')
      .onSnapshot(querySnapshot => {
        const errorItems = [];
        querySnapshot.forEach(doc => errorItems.push(doc.data()));
        setErrors(errorItems);
      });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, []);

  const makeCSV = content => {
    let csv = '';
    content.forEach(value => {
      value.forEach((item, i) => {
        const innerValue = item === null ? '' : item.toString();
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = `"${result}"`;
        }
        if (i > 0) {
          csv += ',';
        }
        csv += result;
      });
      csv += '\n';
    });
    return csv;
  };

  const downloadTxtFile = () => {
    const contents = [];
    contents.push(['Product', 'Line', 'Error']);
    errors.forEach(error => {
      contents.push([error.item.itemName, error.line, error.error]);
    });
    const output = makeCSV(contents);
    const data = new Blob(
      [
        'Dear user, the Square platform has detected problems when trying to create your articles. The errors may be due to invalid information in some of the fields. It is recommended to review the following articles and proceed to re-upload the csv file. If you are still experiencing problems you can contact us https://seeed.us:\r\n',
        output
      ],
      { type: 'text/txt' }
    );
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'erros.txt');
    tempLink.click();
  };

  return (
    <div tw="w-full flex flex-col mt-3">
      <span tw="mb-1">Upload Status</span>
      <div tw="mb-3 w-11/12 self-center">
        <Progress
          percent={Math.floor(
            ((numDoneItems + errors.length) / numItems) * 100
          )}
          status="active"
        />
      </div>
      <div tw="mb-3">Items uploaded to Square: {numDoneItems}</div>
      <div tw="mb-3">Items with errors: {errors.length}</div>
      {!!errors.length && (
        <>
          <div tw="text-left px-2 max-h-48">
            <button
              type="button"
              tw="flex items-center rounded border border-electric-green p-2 mt-4 mb-2 pr-2 text-light-grey text-xs hover:border-yellow-green hover:text-light-grey"
              onClick={() => {
                downloadTxtFile();
              }}
            >
              Download errors
            </button>
          </div>
          <div tw="grid grid-cols-errors-table px-2 max-h-48 overflow-y-auto">
            <div tw="col-span-1 border-b border-solid border-table-grey text-table-text pl-6">
              Item
            </div>
            <div tw="col-span-1 border-b border-solid border-table-grey text-table-text text-center">
              Line
            </div>
            {errors.map(error => {
              return (
                <>
                  <div tw="col-span-1 border-b border-solid border-table-grey pl-3">
                    {error.item.itemName}
                  </div>
                  <div tw="col-span-1 border-b border-solid border-table-grey text-center pl-1">
                    {error.line}
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}
      {numItems === numDoneItems + errors.length && (
        <>
          <div tw="w-11/12 self-center">
            <Result
              status="success"
              title="Inventory successfully uploaded to Square!"
              classNames={resultOverrideStyles}
            />
          </div>
          <a
            tw="flex items-center justify-center rounded border border-electric-green p-1 pr-2 mb-1 h-8 text-light-grey text-sm hover:border-yellow-green hover:text-light-grey"
            href="https://squareup.com/dashboard/items/library"
          >
            <span tw="leading-none">See your Inventory!</span>
          </a>
        </>
      )}
    </div>
  );
}
