// Dependencies
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { jsx } from '@emotion/core';
import 'twin.macro';
import { message } from 'antd';

// Env
import env from '@env';

// Firestore
import db from '@db';

// State Container
import ProcessableProductsObject from '@providers/ProcessableProductsObject';
import UserData from '@providers/UserData';

// Components
import ItemTable from './ItemTable';
import Step4 from './Step4';

// Helper Functions
import {
  createFirestoreInventoryDocument,
  createFirestoreItemsCollection,
  deleteExistingFirestoreInventory,
  checkAppStatus,
  writeAppDataToFirestore,
  getLocationId
} from './fns/firestoreFns';

/**
 * @function Preview
 * React Functional Component
 *
 * The preview component contains a table containing all the user's inventory formatted
 * into the columns/fields of the template csv.
 * This allows the user to check and see that everything was parsed ok.
 *
 * @state {bool} isError
 * @state {string} errorMessage
 * @state {string} uploadStage
 */
function Preview() {
  const history = useHistory();
  const [
    processableProductsObject,
    setProcessableProductsObject
  ] = ProcessableProductsObject.useContainer();
  const userData = UserData.useContainer();
  const [isError, setIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadStage, setUploadStage] = useState('');

  /**
   * Display errorMessage if there is one.
   * Reset upload stage after error
   */
  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage, 10);
      setUploadStage('');
    }
  }, [errorMessage]);

  /** on mount useEffect */
  useEffect(() => {
    let containsError = false;
    if (processableProductsObject.items) {
      window.localStorage.setItem(
        'processableProductsObject',
        JSON.stringify(processableProductsObject)
      );
      // check that document length is less that 1500
      if (processableProductsObject.items.length > 1501) {
        setErrorMessage(
          'Your document contains too many products. We can only process up to 1500 at a time.'
        );
        containsError = true;
      }
      let currencyWarning = false;
      const hasRequiredFields = processableProductsObject.items.every(item => {
        // check that each item has a name
        if (!item.item.itemName) {
          console.log(item.item);
          return false;
        }
        // set warning if an item has a price but no currency
        // a currency value is requried if a price field is given to the Square API
        if (!item.item.currency && item.item.price) {
          currencyWarning = true;
          return false;
        }
        if (
          item.variants.length &&
          !item.variants.every(variant => {
            // again, check for currency value if there is a price
            if (!variant.currency && variant.price) {
              currencyWarning = true;
              return false;
            }
            return !!variant.itemName;
          })
        ) {
          return false;
        }
        return true;
      });
      // sets error message if one was caught
      if (!hasRequiredFields) {
        if (currencyWarning) {
          setErrorMessage(
            'The currency field for some priced items is missing.'
          );
        } else {
          setErrorMessage(
            'Missing required fields. All items must include a name'
          );
        }
        containsError = true;
      }
    }
    if (!containsError) setIsError(false);
  }, []);

  /**
   * @function acceptClickHandler
   * Makes a check to see if the app is not processing another inventory,
   * then updates the app process status in firestore,
   * then makes a series of calls to cloud functions to write inventory to firestore,
   * which will then trigger another cloud function to create items in square.
   *
   * If there is a problem it will give a value to errorMessage
   * which will trigger the useEffect that stops the process and displays a message
   */
  async function acceptClickHandler() {
    try {
      // get info on last inventory that used the app
      console.log(`${env().ENVIRONMENT}-inventories`);
      const lastAppUse = await db
        .collection(`${env().ENVIRONMENT}-inventories`)
        .doc('last_process_start_time')
        .get()
        .then(doc => doc.data())
        .catch(err => console.error(err));

      // if the app didn't record it's termination
      // and its been less than 20 mins since the last process started
      // check the status of the app
      if (new Date() - new Date(lastAppUse.start_time) < 150000) {
        // get app status
        const appStatus = await checkAppStatus(
          userData.merchantId,
          userData.token
        );
        // if processing or there was an error in getting status do not allow to continue
        if (appStatus === 'PROCESSING') {
          setErrorMessage(
            'We are currently processing another order, please try again later.'
          );
          setUploadStage('');
          return;
        }
        if (!appStatus || appStatus === 'ERROR') {
          setErrorMessage('There was an error. Please try again later.');
          setUploadStage('');
          return;
        }
      }

      // update current process info in firestore
      db.collection(`${env().ENVIRONMENT}-inventories`)
        .doc('last_processed_inventory')
        .set({
          inventory: userData.merchantId
        });

      db.collection(`${env().ENVIRONMENT}-inventories`)
        .doc('last_process_start_time')
        .set({
          start_time: new Date().toString()
        });

      await deleteExistingFirestoreInventory(userData.merchantId);
      const { data: locationId } = await getLocationId(userData.token);
      await createFirestoreInventoryDocument(
        processableProductsObject.categories,
        userData.merchantId,
        userData.token,
        locationId
      );
      await createFirestoreItemsCollection(
        processableProductsObject.items,
        userData.merchantId,
        userData.token,
        locationId
      );
      writeAppDataToFirestore(
        window.localStorage.getItem('squareEmail'),
        userData.merchantId,
        processableProductsObject.items.length
      );
      setUploadStage('UPLOADING');
    } catch (err) {
      setUploadStage('');
      console.error(err);
      setErrorMessage('Could not process request, please try again later');
    }
  }

  /**
   * @function declineClickHandler
   * Removes processableProductsObject from local storage,
   * empties processableProductsObject provider,
   * and redirects to the uploadfile page
   */
  function declineClickHandler() {
    window.localStorage.removeItem('processableProductsObject');
    setProcessableProductsObject({});
    history.push('/uploadfile?retry=true');
  }

  return (
    <>
      <div tw="flex flex-col justify-center items-center w-10/12">
        <h3 tw="text-2xl mt-10 text-white self-start">
          Step 3 Preview your Data
        </h3>
        {processableProductsObject.items && (
          <>
            <ItemTable processableItems={processableProductsObject.items} />
            <div tw="text-2xl text-white">
              Total Items: {processableProductsObject.items.length}
            </div>
            <div tw="flex justify-center my-12">
              <button
                type="button"
                tw="w-56 p-2/5 bg-button-green mr-4 text-white rounded hover:bg-blue-800 disabled:opacity-50"
                onClick={() => {
                  setUploadStage('PROCESSING');
                  acceptClickHandler();
                }}
                disabled={!!uploadStage || isError || uploadStage}
              >
                Perfect! Upload my inventory
              </button>
              <button
                type="button"
                tw="w-56 p-2/5 bg-button-red ml-4 text-white rounded hover:bg-red-800 disabled:opacity-50"
                onClick={declineClickHandler}
                disabled={!!uploadStage}
              >
                Nope I need to Try Again
              </button>
            </div>
          </>
        )}
        {uploadStage && (
          <div tw="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-70 z-30" />
        )}
      </div>
      {uploadStage && <Step4 uploadStage={uploadStage} />}
    </>
  );
}

export default Preview;
