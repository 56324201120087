// Dependencies
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Firestore db
import db from '@db';

// Env
import env from '@env';

// typedefs
// eslint-disable-next-line no-unused-vars
const typedefs = require('../../../typedefs');

/**
 * @function checkAppStatus
 * Makes an axios request to a HTML cloud function
 * that checks the status of the app
 *
 * @param {string} merchantId The merchant ID of user's square store
 * @param {string} token User's Square oAuth token
 *
 * @returns {string} this is a string containing the app status
 */
export const checkAppStatus = (merchantId, token) => {
  return axios
    .post(
      `${env().CLOUD_FUNCTIONS_BASE_URL}/${
        env().ENVIRONMENT
      }-api/checkAppStatus`,
      {
        merchantId,
        token
      }
    )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      return 'ERROR';
    });
};

/**
 * @function deleteExistingFirestoreInventory
 * Axios call to HTML triggered cloud function
 * to delete any existing data matching sent merchantId
 *
 * @param {string} merchantId The merchant ID of user's square store
 * @returns {Object} Response object. Returns a 201 status for successfull inventory deletes
 * or if no corresponding inventory was found.
 */
export const deleteExistingFirestoreInventory = merchantId => {
  return axios
    .post(
      `${env().CLOUD_FUNCTIONS_BASE_URL}/${
        env().ENVIRONMENT
      }-checkAndDeleteExistingInventory`,
      {
        merchantId
      }
    )
    .then(res => res);
};

/**
 * @function createFirestoreInventoryDocument
 * Makes axios call to cloud function which creates an inventory in Firestore
 *
 * @param {Array.<string>} categories
 * @param {string} merchantId User's Square merchant ID
 * @param {string} token User's Square oAuth token
 */
export const createFirestoreInventoryDocument = (
  categories,
  merchantId,
  token,
  locationId
) => {
  return axios
    .post(`${env().CLOUD_FUNCTIONS_BASE_URL}/${env().ENVIRONMENT}-inventory`, {
      categories,
      token,
      merchantId,
      locationId
    })
    .then(res => res);
};

/**
 * @function createFirestoreItemsCollection
 * Makes axios call to cloud function which creates inventory items in Firestore
 *
 * @param {Array.<typedefs.Item>} items User's inventory items
 * @param {string} merchantId User's Square merchant ID
 * @param {string} token User's Square oAuth token
 */
export const createFirestoreItemsCollection = (
  items,
  merchantId,
  token,
  locationId
) => {
  return axios
    .post(`${env().CLOUD_FUNCTIONS_BASE_URL}/${env().ENVIRONMENT}-items`, {
      items,
      merchantId,
      token,
      locationId
    })
    .then(res => res);
};

/**
 * @function writeAppDataToFirestore
 * Using firestore client write App usage data to firestore
 *
 * @param {string} email User's inventory items
 * @param {string} merchantId User's Square merchant ID
 * @param {number} numItems User's Square oAuth token
 */
export const writeAppDataToFirestore = (email, merchantId, numItems) => {
  if (process.env.ENVIRONMENT === 'prod') {
    db.collection('app-data')
      .doc(uuidv4())
      .set({ email, merchantId, numItems });
  } else {
    db.collection('dev-app-data')
      .doc(uuidv4())
      .set({ email, merchantId, numItems });
  }
};

//
export const getLocationId = async token => {
  return axios
    .get(`${env().SQUARE_SERVICE}/location/?token=${token}`)
    .then(response => response.data)
    .catch(errors => errors.data);
};
