// import helper function
import extractProductInfo from './extractProductInfo';

/**
 * @function templateCreateProcessableProductsObject
 * Extracts all information from CSV and puts it into a processable object
 *
 * @param {typedefs.DocKey} docKeyObject
 * @param {Array.<Array.<string>>} fileArray
 * @param {string} currencyType
 * @returns {typedefs.processableProductsObject}
 */
export default function templateCreateProcessableProductsObject(
  docKeyObject,
  fileArray
) {
  const processableDocArray = [];
  const categories = {};
  for (let i = 1; i < fileArray.length; i += 1) {
    const categoryName = fileArray[i][docKeyObject.category];
    if (categoryName && !categories[categoryName]) {
      categories[fileArray[i][docKeyObject.category]] =
        fileArray[i][docKeyObject.category];
    }
    const item = fileArray[i];
    const groomedProductInfo = {
      item: extractProductInfo(item, docKeyObject),
      variants: [],
      line: i
    };
    let nextItem = fileArray[i + 1];
    while (
      i + 1 < fileArray.length &&
      nextItem[docKeyObject.type] &&
      nextItem[docKeyObject.type].toLowerCase() === 'variant'
    ) {
      groomedProductInfo.variants.push(
        extractProductInfo(nextItem, docKeyObject)
      );
      i += 1;
      nextItem = fileArray[i + 1];
      if (!nextItem) break;
    }
    processableDocArray.push(groomedProductInfo);
  }
  return {
    items: processableDocArray,
    categories: Object.values(categories)
  };
}
