// import helper function
import extractProductInfo from './extractProductInfo';

/**
 * @function templateCreateProcessableProductsObject
 * Extracts all information from CSV and puts it into a processable object
 *
 * @param {typedefs.DocKey} docKeyObject
 * @param {Array.<Array.<string>>} fileArray
 * @param {string} currencyType
 * @returns {typedefs.processableProductsObject}
 */
export default function templateCreateProcessableProductsObject(
  docKeyObject,
  fileArray,
  currencyType
) {
  const processableDocArray = [];
  const categories = {};
  const variationNameIndex = fileArray[0].indexOf('Variation Name');
  for (let i = 1; i < fileArray.length; i += 1) {
    const categoryName = fileArray[i][docKeyObject.category];
    if (categoryName && !categories[categoryName]) {
      categories[fileArray[i][docKeyObject.category]] =
        fileArray[i][docKeyObject.category];
    }
    const item = fileArray[i];
    const groomedProductInfo = {
      item: extractProductInfo(item, docKeyObject),
      variants: [],
      line: i
    };

    const variationName = item[variationNameIndex];

    // if "Variation Name" is not default add variants
    if (variationName !== 'Default') {
      let variantItem = item;
      while (variantItem && variantItem[variationNameIndex] !== 'Default') {
        const variantInfo = extractProductInfo(variantItem, docKeyObject);
        variantInfo.itemName = variantItem[variationNameIndex];
        variantInfo.type = 'variant';
        variantInfo.currency = currencyType;
        variantInfo.category = '';
        variantInfo.description = '';
        groomedProductInfo.variants.push(variantInfo);
        i += 1;
        variantItem = fileArray[i + 1];
      }

      // clean up parent item
      groomedProductInfo.item.type = 'variant';
      groomedProductInfo.sku = '';
      groomedProductInfo.price = '';
      groomedProductInfo.stock = '';
    } else {
      // add fields to normal item
      groomedProductInfo.item.type = 'normal';
      groomedProductInfo.item.currency = currencyType;
    }
    processableDocArray.push(groomedProductInfo);
  }
  return {
    items: processableDocArray,
    categories: Object.values(categories)
  };
}
