// eslint-disable-next-line no-unused-vars
const typedefs = require('../../../../typedefs');

/**
 * @see createDocKey below for info about this module
 */

function createWooDocKey(columns) {
  const docKey = {};
  docKey.itemName = columns.indexOf('Name');
  docKey.description = columns.indexOf('Description');
  docKey.category = columns.indexOf('Categories');
  docKey.type = columns.indexOf('Type');
  docKey.price = columns.indexOf('Regular price');
  docKey.currency = null;
  docKey.stock = columns.indexOf('Stock');
  docKey.priceType = null;
  docKey.sku = columns.indexOf('SKU');
  docKey.imageUrl = columns.indexOf('Images');
  return docKey;
}

function createShopifyDocKey(columns, categoryColumnIndex) {
  const docKey = {};
  docKey.itemName = columns.indexOf('Title');
  docKey.description = columns.indexOf('Body (HTML)');
  docKey.category = categoryColumnIndex;
  docKey.type = null;
  docKey.price = columns.indexOf('Variant Price');
  docKey.currency = null;
  docKey.stock = columns.indexOf('Variant Inventory Qty');
  docKey.priceType = null;
  docKey.sku = columns.indexOf('Variant SKU');
  docKey.imageUrl = columns.indexOf('Image Src');
  return docKey;
}

function createSquareDocKey(columns) {
  const docKey = {};
  docKey.itemName = columns.indexOf('Item Name');
  docKey.description = columns.indexOf('Description');
  docKey.category = columns.indexOf('Category');
  docKey.type = null;
  docKey.price = columns.indexOf('Price');
  docKey.currency = null;
  docKey.stock = columns.indexOf('Current Quantity My Store');
  docKey.priceType = null;
  docKey.sku = columns.indexOf('SKU');
  docKey.imageUrl = null;
  return docKey;
}

/**
 * @function createDocKey
 * Create a docKey object depending on the type of uploaded document.
 * The docKey object is used to map column name locations to the fileArray so that
 * the correct information can be extracted from the document.
 *
 * Example a document is uploaded and 'description' is the 5th column.
 * When the CSV/XLSX file is parsed to an array the first index in that array is another array
 * containing all the column names. Here, the indexOf method is called on that array
 * to find the index that contains the string 'description'.
 * In this case it would be 4. This value is then added to the docKey object property 'description'.
 * When the information is being extracted from each item in the file array, it checks the
 * docKey to know where to look for that specific information.
 * In this case each item at index 4 will contain the description of that item.
 *
 * @param {string} csvType
 * @param {Array.<string>} columns
 * @param {number} categoryColumnIndex
 * @returns {typedefs.DocKey}
 */
export default function createDocKey(csvType, columns, categoryColumnIndex) {
  let docKey = {};
  switch (csvType) {
    case 'template':
      docKey = {
        itemName: 0,
        description: 1,
        category: 2,
        type: 3,
        price: 4,
        currency: 5,
        stock: 6,
        priceType: 7,
        sku: 8,
        imageUrl: 9
      };
      break;
    case 'woo':
      docKey = createWooDocKey(columns);
      break;
    case 'shopify':
      docKey = createShopifyDocKey(columns, categoryColumnIndex);
      break;
    case 'square':
      docKey = createSquareDocKey(columns);
      break;
    default:
      console.error('invalid csv type');
  }
  return docKey;
}
