// import createProcessableProductsObject functions
import wooCreateProcessableProductsObject from './wooCreateProcessableProductsObject';
import templateCreateProcessableProductsObject from './templateCreateProcessableProductsObject';
import shopifyCreateProcessableProductsObject from './shopifyCreateProcessableProductsObject';
import squareCreateProcessableProductsObject from './squareCreateProcessableProductsObject';

// eslint-disable-next-line no-unused-vars
const typedefs = require('../../../../typedefs');

/**
 * @function createProcessableProductsObject
 * This function is an index for creating a processblaeProductsObject
 * based on the type of document uploaded.
 *
 * @param {typedefs.DocKey} docKeyObject
 * @param {Array.<Array.<string>>} fileArray
 * @param {string} docType
 * @param {string} currencyType
 *
 * @returns {typedefs.processableProductsObject}
 * */
export default (docKeyObject, fileArray, docType, currencyType) => {
  switch (docType) {
    case 'template':
      return templateCreateProcessableProductsObject(docKeyObject, fileArray);
    case 'woo':
      return wooCreateProcessableProductsObject(
        docKeyObject,
        fileArray,
        currencyType
      );
    case 'shopify':
      return shopifyCreateProcessableProductsObject(
        docKeyObject,
        fileArray,
        currencyType
      );
    case 'square':
      return squareCreateProcessableProductsObject(
        docKeyObject,
        fileArray,
        currencyType
      );
    default:
      throw new Error('Invalid document source type');
  }
};
