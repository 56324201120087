// eslint-disable-next-line no-unused-vars
const typedefs = require('../../../../typedefs');

/**
 * @function extractProductInfo
 * Takes an item array from the file array and extracts all the info
 * into a processable form @see typedefs.ItemInfo using the docKeyObject
 *
 * @param {Array.<string>} fileArrayItem
 * @param {typedefs.DocKey} docKeyObject
 *
 * @returns {typedefs.ItemInfo}
 */
export default function extractProductInfo(fileArrayItem, docKeyObject) {
  const info = {};
  Object.keys(docKeyObject).forEach(key => {
    if (docKeyObject[key] !== null) {
      info[key] = fileArrayItem[docKeyObject[key]];
    } else info[key] = '';
  });
  return info;
}
