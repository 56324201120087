export default () => {
  return {
    SQUARE_URL: process.env.SQUARE_URL,
    SQUARE_APP_ID: process.env.SQUARE_APP_ID,
    SQUARE_SCOPES: process.env.SQUARE_SCOPES,
    CLOUD_FUNCTIONS_BASE_URL: process.env.CLOUD_FUNCTIONS_BASE_URL,
    ENVIRONMENT: process.env.ENVIRONMENT,
    API_AUTH0_SERVICE: process.env.API_AUTH0_SERVICE,
    DOMAIN_AUTH0: process.env.DOMAIN_AUTH0,
    CLIENT_ID_AUTH0: process.env.CLIENT_ID_AUTH0,
    AUDIENCE_AUTH0: process.env.AUDIENCE_AUTH0,
    SQUARE_SERVICE: process.env.SQUARE_SERVICE
  };
};
