// Dependencies
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import 'twin.macro';
import PropTypes from 'prop-types';
import { Table } from 'antd';

// Import css overrides for Antd components
import antdOverrideStyles from '../paginationOverride.css';
import tableOverrideStyles from './tableOverrideStyles.css';

// Typedefs
const typedefs = require('../../../typedefs');

/**
 * @function ItemTable
 * Functional Component ItemTable
 *
 * An antd table that shows all of the information uploaded by the user in the Template CSV format
 *
 * Props:
 * @param {Array.<typedefs.Item>}
 *
 */
function ItemTable({ processableItems }) {
  // columns config for antd table
  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'center',
      width: '10rem'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: '15rem'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
      width: '10rem'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '5rem'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      width: '10rem'
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      align: 'center',
      width: '6rem'
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      key: 'stock',
      align: 'center',
      width: '6rem'
    },
    {
      title: 'Price Type',
      dataIndex: 'priceType',
      key: 'priceType',
      align: 'center',
      width: '6rem'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      align: 'center',
      width: '6rem'
    },
    {
      title: 'Image URL',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      align: 'center',
      width: '40rem'
    }
  ];

  // put all variants in children property to use have dropdown boxes for variants
  let count = 0;
  const data = processableItems.map(item => {
    const newItem = { key: (count += 1), ...item.item };
    if (item.variants.length) {
      newItem.children = item.variants.map(variant => {
        const newVariant = { ...variant, key: (count += 1) };
        return newVariant;
      });
    }
    return newItem;
  });

  return (
    <div tw="w-full">
      <Table
        columns={columns}
        classNames={tableOverrideStyles}
        dataSource={data}
        size="small"
        scroll={{ y: 350, x: '85vw' }}
        pagination={{
          pageSizeOptions: ['20', '50', '100', '200', '500'],
          showSizeChanger: true,
          classNames: { antdOverrideStyles }
        }}
        bordered
      />
    </div>
  );
}

ItemTable.propTypes = {
  processableItems: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.objectOf(PropTypes.string),
      variants: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    })
  ).isRequired
};

export default ItemTable;
