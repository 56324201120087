// Dependencies
/** @jsx jsx */
import { jsx } from '@emotion/core';
import 'twin.macro';
import React from 'react';
import queryString from 'query-string';

/**
 * Arrays of <p> elements containing information about different types of CSVs
 */
const wooInfo = [
  <p>
    &bull; Only items of type <strong>&quot;simple&quot;</strong> and{' '}
    <strong>&quot;variable/variation&quot;</strong> will be created.
  </p>,
  <p>
    &bull; If an item contains multiple images, only the first will be uploaded.
  </p>,
  <p>
    &bull; Only 1 category is permitted by square, if your list contains
    subcategories, only the parent category will be used.
  </p>,
  <p>
    &bull; Only prices listed in <strong>&quot;Regular price&quot;</strong> will
    be used
  </p>,
  <p>
    &bull; WooCommerce docs <strong>must</strong> contain the columns{' '}
    <strong>&quot;ID&quot;</strong> and <strong>&quot;Parent&quot;</strong>. If
    not using variants they can be empty.
  </p>,
  <p>&bull; Please be sure to select a currency before viewing the preview</p>
];
const shopifyInfo = [
  <p>
    &bull; If a <strong>&quot;Status&quot;</strong> column is included only
    items marked &quot;active&quot; will be used. If not using status it is
    recommeneded to omit it from your document.
  </p>,
  <p>
    &bull; To use categories a column must be selected from your document. You
    can use an existing one or create a custom column for item categories. NOTE:
    If category hierachies designated by &quot;&gt;&quote; are used, only the
    top level category will be used.
  </p>,
  <p>
    &bull; If an item contains multiple images, only the first will be uploaded.
  </p>,
  <p>
    &bull; Column names must be written with the first letter of each word
    capitalized. ex. Title, Option1 Value
  </p>,
  <p>&bull; Please be sure to select a currency before viewing the preview</p>,
  <p>
    &bull; The <strong>&quot;Title&quot;</strong> column is required for all
    non-variant items.
  </p>,
  <p>
    &bull; All non-variant items without a <strong>&quot;Title&quot;</strong>{' '}
    will be skipped.
  </p>,
  <p>
    &bull; The <strong>&quot;Option1 Value&quot;</strong> column is required for
    all variant items.
  </p>,
  <p>
    &bull; Variant item names will be taken from all the option value columns
  </p>
];

/**
 * @component InfoPage
 * A component that takes the value from 'doc' in the url query string
 * and displays the related information
 */
const InfoPage = () => {
  const docType = queryString.parse(window.location.search).doc;
  let info = [];
  let titleText = '';
  switch (docType) {
    case 'woo':
      info = wooInfo;
      titleText = 'WooCommerce';
      break;
    case 'shopify':
      info = shopifyInfo;
      titleText = 'Shopify';
      break;
    default:
      info = [<p>&bull; Sorry the page you reached is not valid.</p>];
  }

  return (
    <div tw="rounded flex flex-col p-8 mx-8 bg-white">
      <div tw="text-2xl underline font-bold pb-4">{titleText}</div>
      <div tw="text-xl text-red-600 pb-4">
        Please read the following carefully to ensure your inventory is uploaded
        correcly
      </div>
      {info}
    </div>
  );
};

export default InfoPage;
