/* eslint-disable no-continue */
// import helper function
import extractProductInfo from './extractProductInfo';

// eslint-disable-next-line no-unused-vars
const typedefs = require('../../../../typedefs');

/**
 * @function removeHTMLSyntaxFromText
 * Some text in CSV/XLSX files use HTML syntax, this function
 * using regex removes all HTML syntax and returns only the text
 * @param {string} text with possible HTML syntax
 * @returns {string} text without HTML syntax
 */
function removeHTMLSyntaxFromText(text) {
  return text.replace(/<\/?[^>]*\/?>/g, '');
}

/**
 * @function shopifyCreateProcessableProductsObject
 * Extracts all information from CSV and puts it into a processable object
 *
 * @param {typedefs.DocKey} docKeyObject
 * @param {Array.<Array.<string>>} fileArray
 * @param {string} currencyType
 * @returns {typedefs.processableProductsObject}
 */
export default function shopifyCreateProcessableProductsObject(
  docKeyObject,
  fileArray,
  currencyType
) {
  const processableDocArray = [];
  const categories = {};

  /**
   * Get indexes of columns needed to obtain other values, such as variant names
   */
  const statusColumnIndex = fileArray[0].indexOf('Status');
  const option1ValueIndex = fileArray[0].indexOf('Option1 Value');
  const option2ValueIndex = fileArray[0].indexOf('Option2 Value');
  const option3ValueIndex = fileArray[0].indexOf('Option3 Value');

  for (let i = 1; i < fileArray.length; i += 1) {
    const item = fileArray[i];

    // if the file has a status column and if has any other
    // value than "active" it should be skipped
    if (statusColumnIndex !== -1 && item[statusColumnIndex] !== 'active') {
      continue;
    }

    // skip items without a name
    if (!item[docKeyObject.itemName]) continue;

    const groomedProductInfo = {
      item: extractProductInfo(item, docKeyObject),
      variants: [],
      line: i
    };

    // add currency type if user has entered one
    if (currencyType) {
      groomedProductInfo.item.currency = currencyType.toUpperCase();
    }

    // if a category column was selected, extract categories
    if (docKeyObject.category) {
      const categoryName = item[docKeyObject.category].split(' > ')[0];
      if (categoryName && !categories[categoryName]) {
        categories[item[docKeyObject.category]] = item[docKeyObject.category];
      }
      groomedProductInfo.item.category = categoryName;
    }

    // extracts only the first image if there are multiple images listed
    [groomedProductInfo.item.imageUrl] = groomedProductInfo.item.imageUrl.split(
      ','
    );

    groomedProductInfo.item.type = 'normal';
    groomedProductInfo.item.description = removeHTMLSyntaxFromText(
      groomedProductInfo.item.description
    );

    // if the item has variants, add them
    if (item[option1ValueIndex] !== 'Default Title') {
      // set first variant to current item to extract variant information from parent item
      let variantItem = item;
      while (
        variantItem &&
        variantItem[option1ValueIndex] &&
        variantItem[option1ValueIndex] !== 'Default Title'
      ) {
        // get variant Info
        const variantInfo = extractProductInfo(variantItem, docKeyObject);

        // get variant name from options
        let variantName = variantItem[option1ValueIndex];
        if (variantItem[option2ValueIndex]) {
          variantName += `, ${variantItem[option2ValueIndex]}`;
        }
        if (variantItem[option3ValueIndex]) {
          variantName += `, ${variantItem[option3ValueIndex]}`;
        }
        variantInfo.itemName = variantName;
        variantInfo.type = 'variant';
        variantInfo.currency = currencyType;
        variantInfo.description = '';

        groomedProductInfo.variants.push(variantInfo);

        // move file index up 1
        i += 1;

        // get next item
        variantItem = fileArray[i];
      }

      // set unused columns in parent item empty
      groomedProductInfo.item.price = '';
      groomedProductInfo.item.currency = '';
      groomedProductInfo.item.stock = '';
      groomedProductInfo.item.SKU = '';
    }

    processableDocArray.push(groomedProductInfo);
  }
  return {
    items: processableDocArray,
    categories: Object.values(categories)
  };
}
