// Dependencies
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Provides
import UserData from '@providers/UserData';

/**
 *
 * @component Private Route
 * A private route component that redirects to '/'
 * if the user is not logged in to square.
 * Check is made seeing if the userData provider has a merchantId value.
 *
 * @prop {element} component the jsx element to be rendered if user is logged in
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const userData = UserData.useContainer();
  return (
    <Route
      {...rest}
      render={props => {
        return userData.merchantId ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export default PrivateRoute;
