// Dependencies
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import tw from 'twin.macro';
import { Input } from 'antd';
import queryString from 'query-string';
import * as auth0 from 'auth0-js';

// env
import env from '@env';

// assets
import googleIcono from '@assets/iconGoogle.png';
import facebookIcono from '@assets/iconFacebook.png';

// components
import ConnectToSquare from './ConnectToSquare';

/**
 * @function Login
 * Functional Component Login
 * Displays login inputs and authenticates their email
 *
 * @state {string} email
 * @state {bool} state
 * @state {string} emailSocial
 * @state {emailStatus} @see emailStatus
 * @state {string} value
 */
export default function Login() {
  const [email, setEmail] = useState('');
  const [state, setState] = useState(false);
  const [emailSocial, setSocial] = useState('');
  const [processLoginSocial, setProcessLoginSocial] = useState(false);
  const [emailStatus, setEmailStatus] = useState({
    isChecked: false,
    message: '',
    isVerified: false
  });
  const [value, setValue] = useState('');
  const { email: emailU } = queryString.parse(window.location.search);

  // webAuth config
  const webAuth = new auth0.WebAuth({
    domain: env().DOMAIN_AUTH0,
    clientID: env().CLIENT_ID_AUTH0,
    redirectUri: `${window.location.origin}`,
    audience: env().AUDIENCE_AUTH0,
    responseType: 'token'
  });

  const sessionWithGoogle = () => {
    webAuth.authorize({
      connection: 'google-oauth2'
    });
  };

  const sessionWithFacebook = () => {
    webAuth.authorize({
      connection: 'facebook'
    });
  };

  const sessionWithEmail = () => {
    setState(!state);
  };

  // on successfull authentication with webAuth, set social, value, and state
  useEffect(() => {
    if (window.location.hash) {
      setProcessLoginSocial(true);
      webAuth.parseHash(window.location.hash, function (err, authResult) {
        if (!err) {
          webAuth.client.userInfo(authResult.accessToken, function (err, user) {
            setSocial(user.email);
            setValue(user.email);
            setState(!state);
          });
        }
      });
    }
  }, [emailSocial]);

  return (
    <div
      css={[
        tw`flex flex-col items-center w-2/5 p-4 text-center bg-white rounded`
      ]}
    >
      <h3 tw="text-2xl leading-none m-0">Step 1</h3>
      <p tw="mt-3 text-sm">
        Create or login, using your existing social media account
      </p>

      <div tw="grid w-full lg:w-1/3 mt-3">
        <button
          type="button"
          disabled={processLoginSocial}
          css={[
            tw`inline-flex items-center px-4 py-2 mb-4 text-lg text-white rounded bg-button-facebook hover:bg-button-facebook-hover focus:outline-none`,
            processLoginSocial
              ? tw`opacity-50 cursor-default`
              : tw`cursor-pointer`
          ]}
          onClick={sessionWithFacebook}
        >
          <img src={facebookIcono} alt="" width="28" />
          <span tw="m-auto"> Sign in with Facebook</span>
        </button>
        <button
          type="button"
          disabled={processLoginSocial}
          css={[
            tw`inline-flex items-center px-4 py-2 text-lg text-white rounded bg-button-google hover:bg-button-facebook-hover focus:outline-none`,
            processLoginSocial
              ? tw`opacity-50 cursor-default`
              : tw`cursor-pointer`
          ]}
          onClick={sessionWithGoogle}
        >
          <img src={googleIcono} alt="" width="28" />
          <span tw="m-auto"> Sign in with Google</span>
        </button>
        {processLoginSocial && (
          <p tw="mt-6 text-lg text-electric-green">
            Processing authentication...
          </p>
        )}
      </div>
      <div tw="grid grid-cols-8 text-center py-5  w-full lg:w-2/5">
        <div>
          <hr tw="border-gray border mt-3" />
        </div>
        <div tw="col-span-6 text-sm">Or enter your email address</div>
        <div>
          <hr tw="border-gray border mt-3" />
        </div>
      </div>
      <div tw=" w-full lg:w-2/5">
        <Input
          value={email || emailU || value}
          disabled={!!emailU || !!value}
          onChange={e => setEmail(e.target.value)}
          placeholder="example@email.com"
          tw="rounded border-gray border h-12"
        />
      </div>
      <div tw="mt-2 text-red-600">{emailStatus.message}</div>
      <div tw="text-sm mt-3 mb-1 items-center">
        {emailStatus.isVerified && <div>Connect to your Square account</div>}
      </div>
      <div>
        <ConnectToSquare
          disabled={!emailStatus.isVerified}
          setEmailStatus={setEmailStatus}
          emailStatus={emailStatus}
          email={email}
          setValue={setValue}
          emailSocial={emailSocial}
        />
      </div>
      {emailU ||
        (emailSocial && (
          <div tw="text-sm mt-3 mb-1 text-center">
            Automatically redirecting you to square authentication
          </div>
        ))}
    </div>
  );
}
