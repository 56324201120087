// Dependencies
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import 'twin.macro';
import PropTypes from 'prop-types';

/**
 * @function PreviewButton
 * React Functional Component
 *
 * Preview button is a simple styled button.
 * When the csv is processing it is disabled.
 *
 * @prop {function} handleOnClick
 * @prop {function} isProcessing
 *
 */
function PreviewButton({ handleOnClick, isProcessing }) {
  return (
    <div tw="flex justify-center mt-4 hover:opacity-50">
      <button
        type="button"
        tw="rounded border border-yellow-green disabled:opacity-50 text-light-grey p-1 w-64 mb-2"
        disabled={isProcessing}
        onClick={handleOnClick}
      >
        Preview
      </button>
    </div>
  );
}
PreviewButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired
};

export default PreviewButton;
