// Dependencies
import React from 'react';
import ReactDOM from 'react-dom';

// Styles
import 'tailwindcss/dist/base.min.css';
import './App.css';
import 'antd/dist/antd.css';

// App
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
