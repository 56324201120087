// Dependencies
/** @jsx jsx */
import { jsx } from '@emotion/core';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';

// Assets
import uploadIcon from '@assets/UploadIcon.png';

/**
 * FileInput
 * React Functional Component
 *
 * FileInput is a styled input used to upload a file calling the onChange function passed in the props
 *
 * @param {function} onChange
 * @param {string} text
 * @param {border} boolean
 */
const FileInput = ({ onChange, text, border, removeFileInput }) => {
  const [fileName, setFileName] = useState('');
  const inputFile = useRef();
  function handleOnChange(e) {
    setFileName(e.target.files[0].name);
    onChange(e);
  }

  useEffect(() => {
    if (removeFileInput) {
      inputFile.current.value = '';
      setFileName();
    }
  }, [removeFileInput]);

  return (
    <div tw="flex flex-col items-center">
      <label
        css={[
          tw`flex items-center justify-center p-1 pr-2 mb-2 text-xs border rounded cursor-pointer border-electric-green text-light-grey hover:border-yellow-green`,
          !border && tw`mb-0 underline border-none`
        ]}
      >
        <input
          type="file"
          onChange={handleOnChange}
          tw="hidden"
          ref={inputFile}
        />
        <img tw="pr-1" src={uploadIcon} alt="" />

        {fileName ? (
          <span>{fileName}</span>
        ) : (
          <span tw="leading-none">{text}</span>
        )}
      </label>
    </div>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  border: PropTypes.bool.isRequired
};

export default FileInput;
