/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// Dependencies
/** @jsx jsx */
import { jsx } from '@emotion/core';
import 'twin.macro';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import queryString from 'query-string';
import { message } from 'antd';

// Import Container
import UserData from '@providers/UserData';

// Assets
import template from '@assets/templateWithInstructions.zip';
import downloadIcon from '@assets/DownloadIcon.png';

// Components
import FileInput from './FileInput';
import FileMap from './FileMap';

/**
 * @function UploadFile
 * React Functional Component
 *
 * Component for uploading a CSV or XLSX file
 *
 * @state {Array.<Array.<string>>} templateFileArray
 * @state {Array.<Array.<string>>} wooFileArray
 * @state {Array.<Array.<string>>} shopifyFileArray
 * @state {string} invalidFileMsg
 * @state {string} selectedDocType
 */
const UploadFile = () => {
  const userData = UserData.useContainer();
  const history = useHistory();
  const [templateFileArray, setTemplateFileArray] = useState(null);
  const [wooFileArray, setWooFileArray] = useState(null);
  const [shopifyFileArray, setShopifyFileArray] = useState(null);
  const [invalidFileMsg, setInvalidFileMsg] = useState('');
  const [selectedDocType, setSelectedDocType] = useState('template');
  const [removeFileInput, setRemoveFileInput] = useState(false);

  function removeFile(setFileArray) {
    setFileArray(null);
  }

  /**
   * @function onFileChange
   * Function for takes a file then reads it and depending on the type processes it using papaparse
   * to create a 2d array with all the values of the document.
   * The first line of the file array will always the column names.
   *
   * If there is an error it sets a value to invalidFileMsg
   *
   * @param {Object} e - the onChange synthetic event
   * @param {Function} setFileArray - the useState function to set the fileArray variable
   */
  function onFileChange(e, setFileArray) {
    const file = e.target.files[0];
    let { type } = file;
    const reader = new FileReader();
    setInvalidFileMsg('');
    setRemoveFileInput(false);
    if (!type) {
      const splitName = file.name.split('.');
      type = splitName[splitName.length - 1];
      if (type.toLowerCase() === 'csv') type = 'text/csv';
      if (type.toLowerCase() === 'xlsx') type = 'application/vnd.ms-excel';
    }
    switch (type) {
      // Handle XLSX files
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        reader.onload = e2 => {
          const data = new Uint8Array(e2.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const csvString = XLSX.write(workbook, {
            type: 'string',
            bookType: 'csv'
          });
          const parsedCsv = Papa.parse(csvString, { skipEmptyLines: 'greedy' });
          const BreakException = {};
          try {
            parsedCsv.data.forEach(function name(row, index) {
              if (selectedDocType === 'template') {
                if (index !== 0) {
                  row[0].replace('"', "'");
                  row[1].replace('"', "'");
                  row[0].replace(';', ',');
                  row[1].replace(';', ',');
                  if (row[1].length > 4096) {
                    setInvalidFileMsg(
                      `The description field must not be longer than 4096 characters on line ${
                        index + 1
                      }.`
                    );
                    throw BreakException;
                  }
                  if (row[0].length > 255) {
                    setInvalidFileMsg(
                      `The item_name field must not be longer than 255 characters ${
                        index + 1
                      }.`
                    );
                    throw BreakException;
                  }
                  if (row[2].length < 1) {
                    setInvalidFileMsg(
                      `Product categories are required, on line: ${index + 1}.`
                    );
                    throw BreakException;
                  }
                  const regex = new RegExp('^[0-9,.]+$');
                  if (!regex.test(row[4])) {
                    setInvalidFileMsg(
                      `The price column can only contain commas, dots or numbers on line: ${
                        index + 1
                      }.`
                    );
                    throw BreakException;
                  }
                }
              }
            });
          } catch (err) {
            if (err !== BreakException) throw err;
            return;
          }
          // Only process if its less that 2000 lines
          if (parsedCsv.data.length < 2001) {
            setInvalidFileMsg('');
            setFileArray(parsedCsv.data);
          } else {
            setInvalidFileMsg(
              'File is too big, only files with 2000 or less entries can be processed.'
            );
          }
        };
        reader.readAsArrayBuffer(file);
        break;
      // Handle CSV files
      case 'text/csv':
        Papa.parse(e.target.files[0], {
          skipEmptyLines: 'greedy',
          complete: parsedCsv => {
            const BreakException = {};
            try {
              parsedCsv.data.forEach(function name(row, index) {
                if (selectedDocType === 'shopify') {
                  if (row[4].length < 1) {
                    setInvalidFileMsg(
                      `Product categories are required, on line: ${index + 1}.`
                    );

                    throw BreakException;
                  }
                }
                if (selectedDocType === 'template') {
                  if (index !== 0) {
                    row[0].replace('"', "'");
                    row[1].replace('"', "'");
                    row[0].replace(';', ',');
                    row[1].replace(';', ',');
                    if (row[1].length > 4096) {
                      setInvalidFileMsg(
                        `The description field must not be longer than 4096 characters on line ${
                          index + 1
                        }.`
                      );
                      throw BreakException;
                    }
                    if (row[0].length > 255) {
                      setInvalidFileMsg(
                        `The item_name field must not be longer than 255 characters ${
                          index + 1
                        }.`
                      );
                      throw BreakException;
                    }
                    if (row[2].length < 1) {
                      setInvalidFileMsg(
                        `Product categories are required, on line: ${
                          index + 1
                        }.`
                      );

                      throw BreakException;
                    }
                    const regex = new RegExp('^[0-9,.]+$');
                    if (!regex.test(row[4])) {
                      setTemplateFileArray(null);
                      setInvalidFileMsg(
                        `The price column can only contain commas, dots or numbers on line: ${
                          index + 1
                        }.`
                      );
                      throw BreakException;
                    }
                  }
                }
              });
            } catch (err) {
              if (err !== BreakException) throw err;
              setRemoveFileInput(true);
              return;
            }

            // Only process if its less that 2000 lines
            if (parsedCsv.data.length < 2001) {
              setInvalidFileMsg('');
              setFileArray(parsedCsv.data);
            } else {
              setInvalidFileMsg(
                'File is too big, only files with 2000 or less entries can be processed.'
              );
            }
          }
        });
        break;
      case 'application/vnd.ms-excel':
        reader.onload = e2 => {
          const data = new Uint8Array(e2.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const csvString = XLSX.write(workbook, {
            type: 'string',
            bookType: 'csv'
          });
          const parsedCsv = Papa.parse(csvString, { skipEmptyLines: 'greedy' });
          const BreakException = {};
          try {
            parsedCsv.data.forEach(function name(row, index) {
              if (selectedDocType === 'template') {
                if (index !== 0) {
                  row[0].replace('"', "'");
                  row[1].replace('"', "'");
                  row[0].replace(';', ',');
                  row[1].replace(';', ',');
                  if (row[1].length > 4096) {
                    setInvalidFileMsg(
                      `The description field must not be longer than 4096 characters on line ${
                        index + 1
                      }.`
                    );
                    throw BreakException;
                  }
                  if (row[0].length > 255) {
                    setInvalidFileMsg(
                      `The item_name field must not be longer than 255 characters ${
                        index + 1
                      }.`
                    );
                    throw BreakException;
                  }
                  if (row[2].length < 1) {
                    setInvalidFileMsg(
                      `Product categories are required, on line: ${index + 1}.`
                    );
                    throw BreakException;
                  }
                  const regex = new RegExp('^[0-9,.]+$');
                  if (!regex.test(row[4])) {
                    setInvalidFileMsg(
                      `The price column can only contain commas, dots or numbers on line: ${
                        index + 1
                      }.`
                    );
                    throw BreakException;
                  }
                }
              }
            });
          } catch (err) {
            if (err !== BreakException) throw err;
            return;
          }
          // Only process if its less that 2000 lines

          if (parsedCsv.data.length < 2001) {
            setInvalidFileMsg('');
            setFileArray(parsedCsv.data);
          } else {
            setInvalidFileMsg(
              'File is too big, only files with 2000 or less entries can be processed.'
            );
          }
        };
        reader.readAsArrayBuffer(file);
        break;
      default:
        setInvalidFileMsg('File type not accepted');
    }
  }

  // displays a message when invalidFileMsg contains a value
  useEffect(() => {
    if (invalidFileMsg) {
      message.error(invalidFileMsg, 10);
    }
  }, [invalidFileMsg]);

  // on mount useEffect
  useEffect(() => {
    // Get square token from query string
    const tokenQuery = queryString.parse(window.location.search).token;
    const merchantId = queryString.parse(window.location.search).merchant_id;
    const isRetry = queryString.parse(window.location.search).retry;
    if (isRetry) return;
    if (!tokenQuery || !merchantId) history.push('/');
    // set user data in the userData provider
    userData.setToken(tokenQuery);
    userData.setMerchantId(merchantId);
  }, []);
  return (
    <div tw="rounded flex flex-col bg-white w-84 p-4">
      <div tw="self-start">
        <h3 tw="text-xl leading-none">Step 2</h3>
        <div tw="text-xs leading-normal mb-4">
          Download our .CSV template so you can match any .CSV to the correct
          fields, then just upload the .CSV file,{' '}
          <span tw="text-red-600">
            keep in mind that due to limitations in the Square API you can only
            upload one image per product.
          </span>
        </div>
      </div>
      <div tw="self-center flex flex-col items-center">
        <a
          tw="flex items-center rounded border border-button-blue p-1 pr-2 text-light-grey text-xs hover:border-yellow-green hover:text-light-grey"
          href={template}
          download="template.zip"
        >
          <img tw="mr-1" src={downloadIcon} alt="" />
          <span tw="leading-none">Download CSV Template</span>
        </a>
        <div tw="my-3 text-xs">Done with your file?</div>
        <FileInput
          onChange={e => {
            onFileChange(e, setTemplateFileArray);
            setSelectedDocType('template');
          }}
          removeFileInput={removeFileInput}
          removeFile={() => removeFile(setTemplateFileArray)}
          text="Upload Template CSV"
          border
        />
        {templateFileArray && selectedDocType === 'template' && (
          <FileMap
            fileArray={templateFileArray}
            isNonTemplateCsv={false}
            docType="template"
          />
        )}
        <div tw="text-xs leading-normal px-3 text-center mt-2">
          Migrating from WooCommerce or Shopify? <br /> Upload them directly
        </div>
        <button
          type="button"
          tw="flex items-center rounded border border-electric-green p-2 mt-4 mb-2 pr-2 text-light-grey text-xs hover:border-yellow-green hover:text-light-grey"
          onClick={() => setSelectedDocType('woo')}
        >
          Upload a WooCommerce File
        </button>
        {selectedDocType === 'woo' && (
          <>
            <FileInput
              onChange={e => {
                onFileChange(e, setWooFileArray);
              }}
              removeFile={() => removeFile(setWooFileArray)}
              text="WooCommerce File"
              border={false}
            />

            {wooFileArray && (
              <FileMap
                fileArray={wooFileArray}
                isNonTemplateCsv
                docType="woo"
              />
            )}
          </>
        )}
        <button
          type="button"
          tw="flex items-center rounded border border-electric-green mt-4 mb-2 p-2 pr-2 text-light-grey text-xs hover:border-yellow-green hover:text-light-grey"
          onClick={() => setSelectedDocType('shopify')}
        >
          Upload a Shopify File
        </button>
        {selectedDocType === 'shopify' && (
          <>
            <FileInput
              onChange={e => {
                onFileChange(e, setShopifyFileArray);
              }}
              removeFile={() => removeFile(setShopifyFileArray)}
              text="Shopify File"
              border={false}
            />

            {shopifyFileArray && (
              <FileMap
                fileArray={shopifyFileArray}
                isNonTemplateCsv
                docType="shopify"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
