// Import helper function
import extractProductInfo from './extractProductInfo';

/**
 * @function wooCreateProcessableProductsObject
 * Extracts all information from CSV and puts it into a processable object
 *
 * @param {typedefs.DocKey} docKeyObject
 * @param {Array.<Array.<string>>} fileArray
 * @param {string} currencyType
 * @returns {typedefs.processableProductsObject}
 */
export default function wooCreateProcessableProductsObject(
  docKeyObject,
  fileArray,
  currencyType
) {
  const processableDocArray = [];
  const categories = {};
  const variableItems = [];
  const variations = [];

  for (let i = 1; i < fileArray.length; i += 1) {
    const item = fileArray[i];

    // map data to a processable object. i.e. uses correct field names: itemName, stock, category, etc...
    const groomedProductInfo = {
      item: extractProductInfo(item, docKeyObject),
      variants: [],
      line: i
    };

    // only process and add item if it is simple, variable, or variation type
    if (
      groomedProductInfo.item.type === 'simple' ||
      groomedProductInfo.item.type === 'variable' ||
      groomedProductInfo.item.type === 'variation'
    ) {
      if (currencyType) {
        groomedProductInfo.item.currency = currencyType.toUpperCase();
      }

      // checks if category exists, if not adds it to category object
      const categoryName = fileArray[i][docKeyObject.category].split(' > ')[0];
      if (categoryName && !categories[categoryName]) {
        categories[categoryName] = categoryName;
      }
      groomedProductInfo.item.category = categoryName;

      // extracts only the first image if there are multiple images listed
      [
        groomedProductInfo.item.imageUrl
      ] = groomedProductInfo.item.imageUrl.split(',');

      // simple types are loaded to DocArray.
      // variable and variation items are push to respective array to process later
      if (groomedProductInfo.item.type === 'simple') {
        groomedProductInfo.item.type = 'normal';
        processableDocArray.push(groomedProductInfo);
      } else if (groomedProductInfo.item.type === 'variable') {
        groomedProductInfo.item.type = 'normal';
        variableItems.push(groomedProductInfo);
      } else if (groomedProductInfo.item.type === 'variation') {
        groomedProductInfo.item.type = 'variant';
        variations.push(groomedProductInfo);
      }
    }
  }

  // adds variations to variable items;
  const parentColIndex = fileArray[0].indexOf('Parent');
  const idIndex = fileArray[0].indexOf('ID');

  variations.forEach(variation => {
    let isId;
    // get parent ID or SKU
    let parentIdentifier = fileArray[variation.line][parentColIndex];
    if (parentIdentifier.includes('id')) {
      [, parentIdentifier] = parentIdentifier.split(':');
      isId = true;
    }
    // find parent item
    const parentItemIndex = variableItems.findIndex(variableItem => {
      return isId
        ? fileArray[variableItem.item.line][idIndex] === parentIdentifier
        : variableItem.item.sku === parentIdentifier;
    });
    // push variation to parent item
    variableItems[parentItemIndex].variants.push(variation.item);
  });

  // add variable items with children to list of items
  processableDocArray.push(...variableItems);

  return {
    items: processableDocArray,
    categories: Object.values(categories)
  };
}
