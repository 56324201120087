import React, { useState } from 'react';
import { createContainer } from 'unstated-next';

/**
 * Provider container using unstated-next for
 * the user's Square oAuth token and merchant ID
 * */
export default createContainer(() => {
  const [token, setToken] = useState('');
  const [merchantId, setMerchantId] = useState('');
  return {
    token,
    merchantId,
    setToken,
    setMerchantId
  };
});
