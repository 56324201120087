// Dependencies
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';

// Providers
import ProcessableProductsObjectContainer from '@providers/ProcessableProductsObject';
import UserData from '@providers/UserData';

// Components
import Login from '@components/Login';
import UploadFile from '@components/UploadFile';
import Preview from '@components/Preview';
import CurrentStatus from '@components/CurrentStatus';
import PrivateRoute from '@components/PrivateRoute';
import HeaderContent from '@components/HeaderContent';
import SeeedLink from '@components/SeeedLink';
import InfoPage from '@components/InfoPage';

/** antd layout and styles */
const { Header, Footer, Content } = Layout;
const contentStyles = {
  minHeight: '69vh',
  backgroundColor: 'black',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10
};
const headerStyles = {
  height: 'unset',
  padding: 0
};
const footerStyles = {
  height: '6vh',
  minHeight: '3rem',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0',
  paddingRight: '1.5rem',
  paddingBottom: '1rem',
  backgroundColor: 'black'
};

/**
 * @component App
 *
 * @Provider ProcessableProductsObjectContainer - object with all user inventory data
 * formatted for processing by Cloud Functions
 * @Provider UserData - object with the users Square oAuth token and merchant ID
 */
const App = () => {
  return (
    <ProcessableProductsObjectContainer.Provider>
      <UserData.Provider>
        <Layout>
          <Header style={headerStyles}>
            <HeaderContent />
          </Header>
          <Content style={contentStyles}>
            <Router>
              <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute
                  exact
                  path="/currentstatus"
                  component={CurrentStatus}
                />
                <Route exact path="/uploadfile" component={UploadFile} />
                <Route exact path="/preview" component={Preview} />
                <Route exact path="/info" component={InfoPage} />
              </Switch>
            </Router>
          </Content>
          <Footer style={footerStyles}>
            <SeeedLink />
          </Footer>
        </Layout>
      </UserData.Provider>
    </ProcessableProductsObjectContainer.Provider>
  );
};

export default App;
