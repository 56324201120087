// Dependencies
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';

// Components
import CurrentStatus from '@components/CurrentStatus';

/**
 * @function Stage4
 * React Functional Component
 *
 * Stage4 is a popup window that shows if the request is process or not, it also renders the current status component
 *
 * Props:
 * @param {string} uploadStage the current stage of the uploading process
 */
const Stage4 = ({ uploadStage }) => {
  return (
    <>
      <div
        css={[
          tw`absolute rounded z-50 left-0 right-0 mx-auto p-3 w-72 bg-white flex flex-col`,
          uploadStage === 'UPLOADING' ? tw`top-29p` : tw`top-43p`
        ]}
      >
        <h3 tw="text-xl leading-none">Step 4</h3>
        <div tw="flex justify-between items-center">
          <div tw="text-xs leading-normal w-48">
            {uploadStage === 'UPLOADING' ? (
              <>
                Request Processed! <br /> Uploading inventory to Square{' '}
              </>
            ) : (
              <>
                Processing your Request. <br /> This may take a few minutes.{' '}
              </>
            )}
          </div>
          <div tw="w-12 h-12 flex justify-center items-center bg-sync-background border border-sync-border border-solid rounded-full">
            {uploadStage === 'UPLOADING' ? (
              <CheckOutlined style={{ color: '#0EB255', fontSize: '1.3rem' }} />
            ) : (
              <SyncOutlined
                spin
                style={{ color: '#0EB255', fontSize: '1.3rem' }}
              />
            )}
          </div>
        </div>
        {uploadStage === 'UPLOADING' && <CurrentStatus />}
      </div>
    </>
  );
};

Stage4.propTypes = {
  uploadStage: PropTypes.string.isRequired
};

export default Stage4;
