/* eslint-disable jsx-a11y/anchor-is-valid */
// Dependencies
/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';

// Env
import env from '@env';

// Assets
import squareButton from '@assets/SquareButton.png';

/**
 * @function ConnectToSquare
 * Component that first verifies the user's email with oAuth then displays
 * a button for them to connect to Square
 */
const ConnectToSquare = ({
  disabled,
  email,
  emailStatus,
  setEmailStatus,
  setValue,
  emailSocial
}) => {
  /**
   * @function validateEmail
   * Using the Auth0 service, validate users email.
   * Sets email status depending on if user needs to validate or not
   * @param {string} userEmail
   * @returns {Promise}
   */
  const validateEmail = userEmail => {
    const options = {
      method: 'GET',
      url: `${env().API_AUTH0_SERVICE}/user/check?email=${userEmail}`,
      headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache'
      }
    };
    return axios(options)
      .then(response => {
        if (response.data.data[0].email_verified) {
          setEmailStatus({
            isChecked: true,
            message: 'Email Verified!',
            isVerified: true
          });
        } else {
          setEmailStatus({
            isChecked: true,
            message: 'We have sent you a verification email',
            isVerified: false
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  /**
   * @function redirect
   * Wait 5 seconds then redirec to Square auth page
   */
  const redirect = () => {
    setTimeout(() => {
      location.href = `${env().SQUARE_URL}/oauth2/authorize?client_id=${
        env().SQUARE_APP_ID
      }&scope=${env().SQUARE_SCOPES}&session=false`;
    }, 5000);
  };

  useEffect(() => {
    const { email: emailU } = queryString.parse(window.location.search);
    if (emailU) {
      validateEmail(emailU);
      redirect();
    }
    if (emailSocial) redirect();
  }, [emailSocial]);

  return (
    <div tw="w-full">
      {emailStatus.isVerified || emailSocial ? (
        <div
          css={[
            tw`rounded border border-solid border-electric-green p-2 pb-3`,
            disabled
              ? tw`opacity-50 cursor-default`
              : tw`cursor-pointer hover:border-yellow-green`
          ]}
        >
          <a
            css={[
              tw`flex flex-col items-center`,
              disabled && tw`cursor-default`
            ]}
            href={
              disabled
                ? ''
                : `${env().SQUARE_URL}/oauth2/authorize?client_id=${
                    env().SQUARE_APP_ID
                  }&scope=${env().SQUARE_SCOPES}&session=false`
            }
            onClick={() => window.localStorage.setItem('squareEmail', email)}
          >
            <span tw="text-light-grey text-sm leading-none mb-3">
              Click here to Authenticate with
            </span>
            <img tw="w-24" src={squareButton} alt="" />
          </a>
        </div>
      ) : (
        <div tw="text-center">
          <button
            type="button"
            css={[
              tw`rounded border border-solid border-gray p-4 leading-none`,
              disabled
                ? tw`cursor-pointer hover:border-yellow-green`
                : tw`opacity-50 cursor-default`
            ]}
            onClick={() => validateEmail(email)}
          >
            Verify Email
          </button>
        </div>
      )}

      {emailSocial || emailStatus.isVerified ? '' : ''}
    </div>
  );
};

ConnectToSquare.propTypes = {
  disabled: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  emailStatus: PropTypes.shape({
    isChecked: PropTypes.bool,
    message: PropTypes.string,
    isVerified: PropTypes.bool
  }).isRequired,
  setEmailStatus: PropTypes.func.isRequired
};

export default ConnectToSquare;
