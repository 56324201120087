/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// Dependencies
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import 'twin.macro';
import PropTypes from 'prop-types';
import { message, Input, Select } from 'antd';

// State Container
import ProcessableProductsObject from '@providers/ProcessableProductsObject';

// HelperFunctions
import createProcessableProductsObject from './fns/createProcessableProductsObject';
import createDocKey from './fns/createDocKey';

// Components
import PreviewButton from './PreviewButton';
import CsvTypeRadioMenu from './CsvTypeRadioMenu';

const { Option } = Select;

/**
 * @function FileMap
 * React Functional Component
 * Originally this component was used to map a users CSV to the template CSV,
 * but now it is used to request missing information depending on the type of CSV.
 * This component also does several checks and background parsing of
 * the inventory data before passing it to the Preview component
 *
 * Props:
 * @param {Array.<Array.<string>>} fileArray
 * @param {bool} isNonTemplateCsv
 * @param {string} docType
 *
 * State
 * @state {bool} isProcessing
 * @state {string} csvType
 * @state {string} currencyType
 * @state {number} categoryColumnIndex
 */
function FileMap({ fileArray, isNonTemplateCsv, docType }) {
  const history = useHistory();
  const [
    ,
    setProcessableProductsObject
  ] = ProcessableProductsObject.useContainer();
  const [isProcessing, setIsProcessing] = useState(false);
  const [csvType, setCsvType] = useState(docType);
  const [currencyType, setCurrencyType] = useState('');
  const [categoryColumnIndex, setCategoryColumnIndex] = useState(
    fileArray[0].indexOf('Type')
  );

  function templateCsvPreviewOnClick() {
    if (fileArray.length < 2) {
      message.error('Your document must contain at least one item', 10);
      return;
    }
    if (!isNonTemplateCsv) {
      if (
        fileArray[0][0].toLowerCase() !== 'item name' ||
        fileArray[0][1].toLowerCase() !== 'description' ||
        fileArray[0][2].toLowerCase() !== 'category' ||
        fileArray[0][3].toLowerCase() !== 'type' ||
        fileArray[0][4].toLowerCase() !== 'price' ||
        fileArray[0][5].toLowerCase() !== 'currency' ||
        fileArray[0][6].toLowerCase() !== 'stock' ||
        fileArray[0][7].toLowerCase() !== 'price-type' ||
        fileArray[0][8].toLowerCase() !== 'sku' ||
        fileArray[0][9].toLowerCase() !== 'image'
      ) {
        message.error(
          'Please make sure the column order and titles of your document are exactly the same as the template file',
          10
        );
        setIsProcessing(false);
        return;
      }
    }

    // make sure there is a currency
    if (isNonTemplateCsv && !currencyType) {
      message.error('Please select a currency type.');
      setIsProcessing(false);
      return;
    }

    // make sure woo commerce doc has "Parent" and "ID" columns
    if (
      csvType === 'woo' &&
      (fileArray[0].indexOf('Parent') === -1 ||
        fileArray[0].indexOf('ID') === -1)
    ) {
      message.error('Missing ID or Parent column.');
      setIsProcessing(false);
      return;
    }

    // make sure square doc has "Variation Name" column
    if (csvType === 'square' && fileArray[0].indexOf('Variation Name') === -1) {
      message.error('Missing Variation Name column.');
      setIsProcessing(false);
      return;
    }

    // create docKey for mapping doc information
    const docKeyObject = createDocKey(
      csvType,
      fileArray[0],
      categoryColumnIndex
    );

    // check to see if file contains all necesary columns
    // -1 used as verified because in createDocKey function, indexOf is used
    // to grab the indexes of columns
    const getKey = Object.keys(docKeyObject).find(
      key => docKeyObject[key] === -1
    );
    const columnsError = {
      shopify: {
        itemName: 'Title',
        description: 'Body (HTML)',
        category: 'Categories',
        type: null,
        price: 'Variant Price',
        currency: null,
        stock: 'Variant Inventory Qty',
        priceType: null,
        sku: 'Variant SKU',
        imageUrl: 'Image Src'
      },
      woo: {
        itemName: 'Item Name',
        description: 'Description',
        category: 'Category',
        type: null,
        price: 'Price',
        currency: null,
        stock: 'Current Quantity My Store',
        priceType: null,
        sku: 'SKU',
        imageUrl: null
      },
      template: {
        itemName: 'Name',
        description: 'Description',
        category: 'Categories',
        type: 'Type',
        price: 'Regular price',
        currency: null,
        stock: 'Stock',
        priceType: null,
        sku: 'SKU',
        imageUrl: 'Images'
      }
    };

    if (Object.values(docKeyObject).includes(-1)) {
      message.error(
        `The "${columnsError[csvType][getKey]}" column has an incorrect name or does not exist.`,
        10
      );
      setIsProcessing(false);
      return;
    }

    setProcessableProductsObject(
      createProcessableProductsObject(
        docKeyObject,
        fileArray,
        csvType,
        currencyType
      )
    );
    history.push('/preview');
  }

  useEffect(() => {
    if (isProcessing) {
      templateCsvPreviewOnClick();
    }
  }, [isProcessing]);

  useEffect(() => {
    setIsProcessing(false);
  }, [fileArray]);

  return (
    <div tw="flex flex-col">
      {isNonTemplateCsv && (
        <>
          {csvType !== 'template' && (
            <>
              <div tw="mt-4 text-xs text-center">
                Please read the following information before uploading your
                inventory
              </div>
              <div tw="flex justify-around mt-4">
                {csvType === 'woo' && (
                  <Link
                    to="/info?doc=woo"
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="underline"
                  >
                    WooCommerce
                  </Link>
                )}
                {csvType === 'shopify' && (
                  <Link
                    to="/info?doc=shopify"
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="underline"
                  >
                    Shopify
                  </Link>
                )}
              </div>
            </>
          )}
        </>
      )}
      {isNonTemplateCsv && (
        <>
          <div tw="mt-4 text-xs text-center">
            Please select the currency of your store:
          </div>
          <div tw="mt-2 self-center">
            <Select placeholder="Select a currency" onChange={setCurrencyType}>
              <Option value="USD">USD</Option>
              <Option value="CAD">CAD</Option>
              <Option value="JPY">JPY</Option>
              <Option value="AUD">AUD</Option>
              <Option value="GBP">GBP</Option>
            </Select>
          </div>
        </>
      )}
      {isNonTemplateCsv && csvType === 'shopify' && (
        <>
          <div tw="mt-4 text-xs text-center">
            Please select a column for item categories (choose NONE to not use
            categories):
          </div>
          <div tw="self-center my-4">
            <Select
              labelInValue
              defaultValue={{ value: fileArray[0].indexOf('Type') }}
              onChange={value => setCategoryColumnIndex(value.value)}
            >
              <Option value={null}>NONE</Option>
              {fileArray[0].map((column, i) => (
                <Option key={column} value={i}>
                  {column}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}
      {(!isNonTemplateCsv || (isNonTemplateCsv && csvType !== 'template')) && (
        <PreviewButton
          handleOnClick={() => setIsProcessing(true)}
          isProcessing={isProcessing}
          currencyType={currencyType}
        />
      )}
    </div>
  );
}

FileMap.propTypes = {
  fileArray: PropTypes.array.isRequired,
  isNonTemplateCsv: PropTypes.bool.isRequired,
  docType: PropTypes.string.isRequired
};

export default FileMap;
