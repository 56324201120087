// Dependencies
/** @jsx jsx */
import { jsx } from '@emotion/core';
import 'twin.macro';
import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

/**
 * @function CsvTypeRadioMenu
 * React Functional Component
 * This component displays various options for the type of CSV the user can upload
 *
 * @param {string} csvType
 * @param {function} setCsvType
 */
const CsvTypeRadioMenu = ({ csvType, setCsvType }) => {
  // radiobuttons styles
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  };

  function csvTypeOnChange(e) {
    setCsvType(e.target.value);
  }
  return (
    <div tw="flex flex-col items-center">
      <Radio.Group onChange={csvTypeOnChange} value={csvType}>
        <Radio style={radioStyle} value="woo">
          WooCommerce
        </Radio>
        <Radio style={radioStyle} value="shopify">
          Shopify
        </Radio>
      </Radio.Group>
    </div>
  );
};

CsvTypeRadioMenu.propTypes = {
  csvType: PropTypes.string.isRequired,
  setCsvType: PropTypes.func.isRequired
};

export default CsvTypeRadioMenu;
