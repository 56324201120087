// Dependencies
import 'firebase/firestore';

// Firebase
import firebase from './firebase';

// Initialize Cloud Firestore
const db = firebase.firestore();

/** Export firestore db */
export default db;
